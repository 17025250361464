<script>
  import screen from '@/helpers/screen'
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A component that has:
   * Desktop: text on left and image on right
   * Mobile: text on top and image on bottom.
   * Used throughout the Essentials Shop for cta, hero, etc.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** The background color of the text. */
      color: {
        type: String,
        default: 'dawn',
        validator: (value) => ['dawn', 'black'].includes(value),
      },
      /** If text needs to be on top on mobile instead of the default at bottom. */
      textOnTop: {
        type: Boolean,
        default: false,
      },
      /** The number of columns this cell should span. */
      colSpan: {
        type: Number,
        default: undefined,
        validator: (value) => [1, 2, 3, 4].includes(value),
      },
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <!-- for purgecss: col-span-1 col-span-2 col-span-3 col-span-4 -->
  <component
    :is="hasLink ? 'BaseLink' : 'div'"
    v-bind="linkProps"
    :class="`flex ${textOnTop ? 'flex-col' : 'flex-col-reverse'} lg:flex-row col-span-${colSpan}`"
  >
    <!-- Text on the left (desktop) / bottom (mobile) -->
    <!-- for purgecss: bg-dawn bg-black -->
    <div
      :class="`z-20 lg:w-1/2 bg-${color} ${
        color === 'black' ? 'text-dawn' : ''
      } flex flex-col justify-center px-5 lg:px-9 xl:px-16 py-7 lg:py-0`"
    >
      <div v-if="$scopedSlots.kicker" class="text-sm lg:text-base xl:text-xl mb-4">
        <slot name="kicker" />
      </div>
      <h2 class="font-heading">
        <slot name="title" />
      </h2>
      <div class="text-sm lg:text-base xl:text-xl leading-snug mt-5 xl:mt-10"
        ><slot name="subtitle"
      /></div>
    </div>

    <!-- Image on the right (desktop) / top (mobile) -->
    <div class="lg:w-1/2">
      <slot name="image" />
    </div>
  </component>
</template>
