<script>
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A component that centers text on top of an image. Used in the Essentials Shop landing page to display categories.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** The text color. */
      textColor: {
        type: String,
        default: 'black',
        validator: (value) => ['black', 'dawn'].includes(value),
      },
      /** Whether this banner is being used as a hero (e.g., in Essentials PLP). */
      isForHero: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <component :is="hasLink ? 'BaseLink' : 'div'" v-bind="linkProps">
    <div v-if="$scopedSlots.image">
      <slot name="image" />
    </div>
    <!-- for purgecss: text-black text-dawn -->
    <UtilityConditionalWrapper :wrap="!$scopedSlots.image" tag="div" class="bg-dawn h-60">
      <div
        :class="`absolute inset-0 flex flex-col justify-center items-center font-heading md:font-light text-center ${
          isForHero ? 'text-4xl md:text-7xl' : 'text-base sm:text-3xl'
        } text-${textColor}`"
        :style="{ lineHeight: isForHero ? 56 + 'px' : '' }"
      >
        <div>
          <slot name="cta" />
          <BaseDivider v-if="isForHero" color="black" :weight="2" />
        </div>
      </div>
    </UtilityConditionalWrapper>
  </component>
</template>
