<script>
  import { get, call } from 'vuex-pathify'
  import { DynamicScroller } from 'vue-virtual-scroller'
  import EssentialsCategoryBanner from '@/components/essentials/EssentialsCategoryBanner.vue'
  import ProductCardGrid from '@/components/marketplace/ProductCardGrid'
  import ProductTile from '@/components/product/ProductTile'
  import ProductSlider from '@/components/product/ProductSlider'
  import EssentialsSortDropdown from '@/components/essentials/EssentialsSortDropdown.vue'
  import EssentialsWideBanner from '@/components/essentials/EssentialsWideBanner.vue'
  import { events } from '@/helpers/gtm'
  import screen from '@/helpers/screen'

  export default {
    components: {
      DynamicScroller,
      EssentialsCategoryBanner,
      ProductCardGrid,
      ProductTile,
      ProductSlider,
      EssentialsSortDropdown,
      EssentialsWideBanner,
    },
    async beforeRouteUpdate(to, from, next) {
      this.$store.set('essentials/setSortedProducts', null)
      this.sortValue = 'default'
      next()
    },
    data() {
      return {
        sortValue: 'default',
        bannerDetails: {
          pantry: {
            title: 'CLEAN HOME / CLEAN INGREDIENTS',
            categorySlug: 'cleaning',
            categoryTitle: 'cleaning',
            color: 'dawn',
          },
          cleaning: {
            title: 'REDUCE / REUSE / REIMAGINE',
            categorySlug: 'pantry',
            categoryTitle: 'pantry',
            color: 'black',
          },
          'bath-body': {
            title: 'GOOD / CLEAN / FUN',
            categorySlug: 'beauty',
            categoryTitle: 'beauty',
            color: 'dawn',
          },
          beauty: {
            title: 'SHOWER WITH LOVE',
            categorySlug: 'bath-body',
            categoryTitle: 'bath & body',
            color: 'black',
          },
        },
      }
    },
    computed: {
      ...get('essentials', [
        'categories',
        'firstTenProducts',
        'loaded',
        'plpIsL2',
        'plpIsL1',
        'l1Category',
        'l1CategorySlug',
        'l1CategoryTitle',
        'currentCategory',
        'currentCategorySlug',
        'currentCategoryTitle',
        'filteredProducts',
        'sortProducts',
        'sortedProducts',
        'sortOptions',
      ]),
      ...get('essentialsCart', ['isMaxInCart']),
      screen,
      productsPerRow() {
        if (this.screen.lg) return 5
        else if (this.screen.md) return 4
        return 2
      },
      finalProducts() {
        return this.sortedProducts ?? this.filteredProducts
      },
      bannerProductIndex() {
        if (this.screen.lg) return 6
        return 5
      },
      bannerProducts() {
        return this.finalProducts.slice(this.bannerProductIndex - 1, this.bannerProductIndex + 1)
      },
      productRows() {
        return this.finalProducts.reduce((productRows, product, productIndex) => {
          let rowIndex = 0
          /** the 2 products that go next to/under the banner shouldn't be in `productRows`,
          adding them into `bannerProducts` instead (see above) */
          if (
            productIndex === this.bannerProductIndex - 1 ||
            productIndex === this.bannerProductIndex
          ) {
            return productRows
          }
          if (productIndex > this.bannerProductIndex) {
            rowIndex += 1
          }
          if (!productRows[rowIndex]) {
            productRows[rowIndex] = {
              id: rowIndex,
              products: [],
            }
          }
          productRows[rowIndex].products.push(product)
          return productRows
        }, [])
      },
    },
    created() {
      events.log({
        name: 'viewed essentials product listing',
        data: { category: this.currentCategorySlug },
      })
    },
    methods: {
      ...call('essentialsCart', ['addToCart']),
      getBannerDetail(property) {
        return this.bannerDetails[this.currentCategorySlug]?.[property]
      },
      goToPdp(product) {
        const routeName =
          this.plpIsL2 || this.plpIsL1 ? 'EssentialsProductPLP' : 'EssentialsProductHome'
        this.$navigate({
          name: routeName,
          params: {
            ...this.$route.params,
            productId: product.id,
            productSlug: product.slug,
            productTitle: product.title,
            type: 'essentials',
          },
        })
      },
    },
  }
</script>

<template>
  <div class="mb-40">
    <RouterView />

    <!-- No hero banner displayed in L2 mobile -->
    <EssentialsCategoryBanner v-if="plpIsL1 || (plpIsL2 && screen.md)" is-for-hero>
      <template v-slot:image>
        <img
          :src="`/static/essentials/categories/${currentCategory.slug}.png`"
          alt=""
          class="h-60 md:h-65 object-cover w-full"
        />
      </template>
      <template v-slot:cta>
        <div class="uppercase">{{ currentCategoryTitle }}</div>
      </template>
    </EssentialsCategoryBanner>

    <div class="md:px-10">
      <template v-if="loaded">
        <div v-if="screen.md" class="flex items-center justify-between py-5">
          <!-- Breadcrumb nav -->
          <div>
            <BaseLinkStyled color="black" :to="{ name: 'EssentialsHome' }">
              Essentials Shop
            </BaseLinkStyled>
            <!-- L1 link if view is L2 -->
            <template v-if="plpIsL2">
              &sol;
              <BaseLinkStyled
                color="black"
                :to="{
                  name: 'EssentialsPLP',
                  params: { categorySlugL1: l1CategorySlug },
                }"
              >
                {{ l1CategoryTitle }}
              </BaseLinkStyled>
            </template>
            <span> &sol; {{ currentCategoryTitle }} ({{ filteredProducts.length }} results) </span>
          </div>
          <!-- Sort dropdown -->
          <EssentialsSortDropdown
            v-model="sortValue"
            :options="sortOptions"
            :disabled="filteredProducts.length <= 0"
            @input="sortProducts"
          >
            Sort by
          </EssentialsSortDropdown>
        </div>
      </template>

      <!-- Product grid -->
      <div v-if="!loaded" class="h-50">
        <BaseSpinner overlay="absolute" />
      </div>

      <DynamicScroller
        v-else-if="productRows.length > 0"
        v-slot="{ item: row, active }"
        :items="productRows"
        :min-item-size="340"
        :buffer="2000"
        page-mode
      >
        <DynamicScrollerItem :item="row" :active="active" :class="`${active ? '' : 'hidden'}`">
          <ProductCardGrid :columns="productsPerRow" class="pt-4 pb-10 px-2 md:px-0">
            <ProductTile
              v-for="product in row.products"
              :key="product.id"
              :product="product"
              type="essentials"
              has-quick-add-button
              :is-max-in-cart="isMaxInCart(product.defaultVariant.id)"
              @addToCart="addToCart"
              @click="goToPdp(product)"
            />
            <template v-if="row.id === 0 && plpIsL1 && bannerDetails[currentCategorySlug]">
              <EssentialsWideBanner
                :col-span="screen.lg ? 3 : 2"
                :to="{
                  name: 'EssentialsPLP',
                  params: {
                    categorySlugL1: getBannerDetail('categorySlug'),
                  },
                }"
                :color="getBannerDetail('color')"
              >
                <template v-slot:title>
                  <span class="font-light text-4xl xl:text-6xl 2xl:text-7xl leading-none">{{
                    getBannerDetail('title')
                  }}</span>
                  <BaseDivider
                    :color="getBannerDetail('color') === 'black' ? 'dawn' : 'black'"
                    :weight="2"
                    class="mt-1 xl:mt-2"
                  />
                </template>
                <template v-slot:subtitle>
                  Shop {{ getBannerDetail('categoryTitle') }}
                  <BaseIcon :size="4" class="align-middle inline-block">
                    <IconChevronRight />
                  </BaseIcon>
                </template>
                <template v-slot:image>
                  <img
                    :src="`/static/essentials/plp/${currentCategorySlug}-${
                      screen.lg ? 'desktop' : 'mobile'
                    }.jpg`"
                    alt=""
                    class="h-full object-cover"
                  />
                </template>
              </EssentialsWideBanner>
              <ProductTile
                v-for="product in bannerProducts"
                :key="product.id"
                :product="product"
                has-quick-add-button
                :is-max-in-cart="isMaxInCart(product.defaultVariant.id)"
                @addToCart="addToCart"
                @click="goToPdp(product)"
              />
            </template>
          </ProductCardGrid>
        </DynamicScrollerItem>
      </DynamicScroller>

      <div v-else class="h-50 flex justify-center items-center">
        No products match the selected filters.
      </div>

      <div v-if="!screen.md" class="px-2 overflow-hidden">
        <ProductSlider
          :products="firstTenProducts"
          :slides-per-view="screen.md ? 3 : 2"
          type="essentials"
          context="modal"
          class="mt-18"
          @selectProduct="goToPdp($event)"
        >
          <template v-slot:heading>
            <span class="underline ml-1">More Products to Check Out</span>
          </template>
        </ProductSlider>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  @import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  .vue-recycle-scroller__item-wrapper {
    /* Prevent clipping the focus outlines on the product cards */
    overflow: visible;
  }
  /* purgecss end ignore */
</style>
