<script>
  import screen from '@/helpers/screen'

  export default {
    model: {
      prop: 'value',
    },
    props: {
      /** An array of objects containing `label` and `value` keys. */
      options: {
        type: Array,
        default: () => [],
      },
      /** Whether the input is disabled. A disabled input is grayed out and cannot be focused. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** The input’s value. */
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        inputRef: null,
      }
    },
    computed: {
      screen,
      isMobile() {
        return !this.screen.md // not (greather or equal to 'md' screen size)
      },
      innerValue: {
        get() {
          return this.value
        },
        set(value) {
          /** Emitted when the input’s value changes. */
          this.$emit('input', value)
        },
      },
    },
  }
</script>

<template>
  <div
    :class="`${disabled ? 'border-gray-light text-gray-opacity68' : 'border-black'} ${
      isMobile ? '' : 'border-1 px-2'
    } overflow-hidden`"
  >
    <span :class="`${isMobile ? 'block' : ''}`"><slot /></span>
    <select
      v-model="innerValue"
      v-ref="inputRef"
      aria-label="Sort"
      :disabled="disabled"
      :class="`${isMobile ? 'absolute top-0 opacity-0' : ' py-2 pr-2'} font-semibold ${
        disabled ? '' : ' cursor-pointer'
      } `"
    >
      <option v-for="(option, optionIndex) in options" :key="optionIndex" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>
