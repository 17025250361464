var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-40"},[_c('RouterView'),_vm._v(" "),(_vm.plpIsL1 || (_vm.plpIsL2 && _vm.screen.md))?_c('EssentialsCategoryBanner',{attrs:{"is-for-hero":""},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{staticClass:"h-60 md:h-65 object-cover w-full",attrs:{"src":("/static/essentials/categories/" + (_vm.currentCategory.slug) + ".png"),"alt":""}})]},proxy:true},{key:"cta",fn:function(){return [_c('div',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.currentCategoryTitle))])]},proxy:true}],null,false,1213782785)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md:px-10"},[(_vm.loaded)?[(_vm.screen.md)?_c('div',{staticClass:"flex items-center justify-between py-5"},[_c('div',[_c('BaseLinkStyled',{attrs:{"color":"black","to":{ name: 'EssentialsHome' }}},[_vm._v("\n            Essentials Shop\n          ")]),_vm._v(" "),(_vm.plpIsL2)?[_vm._v("\n            /\n            "),_c('BaseLinkStyled',{attrs:{"color":"black","to":{
                name: 'EssentialsPLP',
                params: { categorySlugL1: _vm.l1CategorySlug },
              }}},[_vm._v("\n              "+_vm._s(_vm.l1CategoryTitle)+"\n            ")])]:_vm._e(),_vm._v(" "),_c('span',[_vm._v(" / "+_vm._s(_vm.currentCategoryTitle)+" ("+_vm._s(_vm.filteredProducts.length)+" results) ")])],2),_vm._v(" "),_c('EssentialsSortDropdown',{attrs:{"options":_vm.sortOptions,"disabled":_vm.filteredProducts.length <= 0},on:{"input":_vm.sortProducts},model:{value:(_vm.sortValue),callback:function ($$v) {_vm.sortValue=$$v},expression:"sortValue"}},[_vm._v("\n          Sort by\n        ")])],1):_vm._e()]:_vm._e(),_vm._v(" "),(!_vm.loaded)?_c('div',{staticClass:"h-50"},[_c('BaseSpinner',{attrs:{"overlay":"absolute"}})],1):(_vm.productRows.length > 0)?_c('DynamicScroller',{attrs:{"items":_vm.productRows,"min-item-size":340,"buffer":2000,"page-mode":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.item;
              var active = ref.active;
return [_c('DynamicScrollerItem',{class:("" + (active ? '' : 'hidden')),attrs:{"item":row,"active":active}},[_c('ProductCardGrid',{staticClass:"pt-4 pb-10 px-2 md:px-0",attrs:{"columns":_vm.productsPerRow}},[_vm._l((row.products),function(product){return _c('ProductTile',{key:product.id,attrs:{"product":product,"type":"essentials","has-quick-add-button":"","is-max-in-cart":_vm.isMaxInCart(product.defaultVariant.id)},on:{"addToCart":_vm.addToCart,"click":function($event){return _vm.goToPdp(product)}}})}),_vm._v(" "),(row.id === 0 && _vm.plpIsL1 && _vm.bannerDetails[_vm.currentCategorySlug])?[_c('EssentialsWideBanner',{attrs:{"col-span":_vm.screen.lg ? 3 : 2,"to":{
                name: 'EssentialsPLP',
                params: {
                  categorySlugL1: _vm.getBannerDetail('categorySlug'),
                },
              },"color":_vm.getBannerDetail('color')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"font-light text-4xl xl:text-6xl 2xl:text-7xl leading-none"},[_vm._v(_vm._s(_vm.getBannerDetail('title')))]),_vm._v(" "),_c('BaseDivider',{staticClass:"mt-1 xl:mt-2",attrs:{"color":_vm.getBannerDetail('color') === 'black' ? 'dawn' : 'black',"weight":2}})]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v("\n                Shop "+_vm._s(_vm.getBannerDetail('categoryTitle'))+"\n                "),_c('BaseIcon',{staticClass:"align-middle inline-block",attrs:{"size":4}},[_c('IconChevronRight')],1)]},proxy:true},{key:"image",fn:function(){return [_c('img',{staticClass:"h-full object-cover",attrs:{"src":("/static/essentials/plp/" + _vm.currentCategorySlug + "-" + (_vm.screen.lg ? 'desktop' : 'mobile') + ".jpg"),"alt":""}})]},proxy:true}],null,true)}),_vm._v(" "),_vm._l((_vm.bannerProducts),function(product){return _c('ProductTile',{key:product.id,attrs:{"product":product,"has-quick-add-button":"","is-max-in-cart":_vm.isMaxInCart(product.defaultVariant.id)},on:{"addToCart":_vm.addToCart,"click":function($event){return _vm.goToPdp(product)}}})})]:_vm._e()],2)],1)]}}])}):_c('div',{staticClass:"h-50 flex justify-center items-center"},[_vm._v("\n      No products match the selected filters.\n    ")]),_vm._v(" "),(!_vm.screen.md)?_c('div',{staticClass:"px-2 overflow-hidden"},[_c('ProductSlider',{staticClass:"mt-18",attrs:{"products":_vm.firstTenProducts,"slides-per-view":_vm.screen.md ? 3 : 2,"type":"essentials","context":"modal"},on:{"selectProduct":function($event){return _vm.goToPdp($event)}},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('span',{staticClass:"underline ml-1"},[_vm._v("More Products to Check Out")])]},proxy:true}],null,false,2070364781)})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }